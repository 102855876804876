import { Box, Flex, Heading } from "@chakra-ui/react";
import Head from "next/head";

export default function Home() {
  return (
    <>
      <Head>
        <title>Matthew Costabile</title>
        <meta
          name="description"
          content="I'm a traveler, photographer, bicyclist, motorcyclist, diy-er, chef, trivia afficianado, beer enthusiast and software engineer"
        />
      </Head>
      <Flex width="100%" height="100%">
        <Box margin="auto" maxW={["80%", "80%", "60%"]}>
          <Heading as="h1" size="3xl">
            Hi, I'm Matt
          </Heading>
          <p>
            I'm a traveler, photographer, bicyclist, motorcyclist, diy-er, chef,
            trivia afficianado, beer enthusiast and software engineer
          </p>
        </Box>
      </Flex>
    </>
  );
}
